import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Link, Drawer, List, ListItem, ListItemText, Collapse, Button } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

// Styles
const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
  nested: {
    paddingLeft: theme.spacing(4),
    fontSize: '12px',
  },
  serviceColor: {
    color: theme.palette.primary.main,
  },
  button: {
    paddingLeft: '30px',
    paddingRight: '30px',
    fontWeight: 'bold',
    fontSize: '18px',
    marginLeft: theme.spacing(2),
  },
}));

const MenuDrawer = (props) => {
  const classes = useStyles();
  const {
    toggleDrawer, drawerState, variant,
  } = props;

  const [serviceOpen, setServiceOpen] = useState(false);
  const [aboutOpen, setAboutUsOpen] = useState(false);

  const handleClickService = () => {
    setServiceOpen(!serviceOpen);
    if (aboutOpen) {
      setAboutUsOpen(!aboutOpen);
    }
  };

  const handleClickAbout = () => {
    setAboutUsOpen(!aboutOpen);
    if (serviceOpen) {
      setServiceOpen(!serviceOpen);
    }
  };

  return (
    <Drawer anchor="right" variant={variant} open={drawerState} onClose={toggleDrawer}>
      <Box className={classes.list}>
        <Link href="/">
          <Box mt={2} ml={2}>
            <img src="/static/images/WGHLogo.svg" width={150} height={70} alt="WGH Logo sidebar" />
          </Box>
        </Link>
        <List>
          <Link href="/" key="home">
            <ListItem button>
              <ListItemText>Home</ListItemText>
            </ListItem>
          </Link>
          <ListItem button onClick={handleClickService} className={classes.serviceColor}>
            <ListItemText>Services</ListItemText>
            {serviceOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={serviceOpen} timeout="auto">
            <List>
              <Link href="/services/ground-handling">
                <ListItem button className={classes.nested}>
                  <ListItemText>Ground Handling</ListItemText>
                </ListItem>
              </Link>
              <Link href="/services/cargo-handling">
                <ListItem button className={classes.nested}>
                  <ListItemText>Cargo handling</ListItemText>
                </ListItem>
              </Link>

              <Link href="/services/anti-icing">
                <ListItem button className={classes.nested}>
                  <ListItemText>De &amp; anti-icing</ListItemText>
                </ListItem>
              </Link>
              <Link href="/services/security-services">
                <ListItem button className={classes.nested}>
                  <ListItemText>Security services</ListItemText>
                </ListItem>
              </Link>
              <Link href="/services/executive-services">
                <ListItem button className={classes.nested}>
                  <ListItemText>Executive services</ListItemText>
                </ListItem>
              </Link>
            </List>
          </Collapse>
          <ListItem button onClick={handleClickAbout} className={classes.serviceColor}>
            <ListItemText>About Us</ListItemText>
            {aboutOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={aboutOpen} timeout="auto">
            <List>
              <Link href="/aboutUs">
                <ListItem button className={classes.nested}>
                  <ListItemText>Overview</ListItemText>
                </ListItem>
              </Link>
              <Link href="/aboutUs?id=1">
                <ListItem button className={classes.nested}>
                  <ListItemText>Vision, Mission and Values</ListItemText>
                </ListItem>
              </Link>
              <Link href="/aboutUs?id=2">
                <ListItem button className={classes.nested}>
                  <ListItemText>Sustainability</ListItemText>
                </ListItem>
              </Link>
              <Link href="/aboutUs?id=3">
                <ListItem button className={classes.nested}>
                  <ListItemText>ISO Certificate</ListItemText>
                </ListItem>
              </Link>
              <Link href="/aboutUs?id=4">
                <ListItem button className={classes.nested}>
                  <ListItemText>Technological Innovation</ListItemText>
                </ListItem>
              </Link>
              <Link href="/aboutUs?id=5">
                <ListItem button className={classes.nested}>
                  <ListItemText>Management</ListItemText>
                </ListItem>
              </Link>
            </List>
          </Collapse>
          <Link href="/locations" key="locations">
            <ListItem button>
              <ListItemText>Locations</ListItemText>
            </ListItem>
          </Link>
          <Link href="/news" key="news">
            <ListItem button>
              <ListItemText>News</ListItemText>
            </ListItem>
          </Link>
          <Link href="/career" key="career">
            <ListItem button>
              <ListItemText>Career</ListItemText>
            </ListItem>
          </Link>
        </List>
        <Link href="/contact" key="contact">
          <Button variant="contained" className={classes.button} size="large" color="primary">
            Contact
          </Button>
        </Link>
      </Box>
    </Drawer>
  );
};

export default MenuDrawer;
