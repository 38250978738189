/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Button, Grid, IconButton, Link, Toolbar, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { useViewportScroll } from 'framer-motion';
import MenuDrawer from '../Drawer/Drawer';

// Styles
const useStyles = makeStyles((theme) => ({
  link: {
    paddingLeft: '40px',
    fontFamily: 'Flama-Semibold',
    lineHeight: 1,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  button: {
    marginLeft: '40px',
    paddingLeft: '30px',
    paddingRight: '30px',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  menuButton: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  background: {
    backgroundColor: 'rgba(255,255,255,0.9)',
  },
  noBackground: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
}));

const TopAppBar = ({ whiteTextColor }) => {
  const classes = useStyles();
  const { scrollY } = useViewportScroll();
  const [scrollOver, setScrollOver] = useState(true);
  const [drawerState, setDrawerState] = useState(false);
  const [showServices, setShowServices] = useState(false);
  const [showAboutUs, setShowAboutUs] = useState(false);
  const [linkColor, setLinkColor] = useState(whiteTextColor ? 'black' : 'black');

  const toggleDrawer = () => {
    setDrawerState(!drawerState);
  };
  const toggleShowServices = () => {
    setShowServices(!showServices);
    if (showAboutUs) {
      setShowAboutUs(!showAboutUs);
    }
  };
  const toggleShowAboutUs = () => {
    setShowAboutUs(!showAboutUs);
    if (showServices) {
      setShowServices(!showServices);
    }
  };

  const handleScroll = () => {
    if (scrollY.current >= 50 && scrollOver === false) {
      setScrollOver(true);
      setLinkColor('black');
    } else if (scrollY.current < 50 && scrollOver === true) {
      setScrollOver(true);
      if (whiteTextColor) {
        setLinkColor('black');
      }
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  return (
    <AppBar
      position="fixed"
      className={scrollOver
        ? classes.background
        : classes.noBackground}
    >
      <Toolbar>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={4} sm={2}>
            <Link href="/">
              <img src="/static/images/WGHLogo.svg" width={100} height={50} alt="WGH logo" />
            </Link>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            item
            xs={8}
            sm={10}
          >
            <Link href="/" key="home" variant="body1" className={classes.link} style={{ color: linkColor }}>
              Home
            </Link>
            <Typography
              style={{ color: linkColor }}
              className={classes.link}
              onClick={toggleShowServices}
            >
              Services
              {showServices ? <ExpandLess /> : <ExpandMore />}
            </Typography>
            <Typography
              style={{ color: linkColor }}
              className={classes.link}
              onClick={toggleShowAboutUs}
            >
              About Us
              {showAboutUs ? <ExpandLess /> : <ExpandMore />}
            </Typography>
            <Link href="/locations" key="locations" variant="body1" className={classes.link} style={{ color: linkColor }}>
              Locations
            </Link>
            <Link href="/news" key="news" variant="body1" className={classes.link} style={{ color: linkColor }}>
              News
            </Link>
            <Link href="/career" key="career" variant="body1" className={classes.link} style={{ color: linkColor }}>
              Career
            </Link>
            <Link href="/contact" key="contact" variant="body1">
              <Button variant="contained" className={classes.button} size="large" color="primary">
                <Typography variant="body1" style={{ fontFamily: 'Flama-Semibold' }}>Contact</Typography>
              </Button>
            </Link>
            <IconButton onClick={toggleDrawer} edge="start" className={classes.menuButton} aria-label="menu">
              <MenuIcon style={{ color: linkColor }} />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
      {showServices
        ? (
          <Toolbar>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Link href="/services/ground-handling" variant="body1" className={classes.link} style={{ color: linkColor }}>
                Ground handling
              </Link>
              <Link href="/services/cargo-handling" variant="body1" className={classes.link} style={{ color: linkColor }}>
                Cargo handling
              </Link>
              <Link href="/services/anti-icing" variant="body1" className={classes.link} style={{ color: linkColor }}>
                De &amp; anti-icing
              </Link>
              <Link href="/services/security-services" variant="body1" className={classes.link} style={{ color: linkColor }}>
                Security services
              </Link>
              <Link href="/services/executive-services" variant="body1" className={classes.link} style={{ color: linkColor }}>
                Executive services
              </Link>
            </Grid>
          </Toolbar>
        )
        : null}
      {showAboutUs
        ? (
          <Toolbar>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Link href="/aboutUs" variant="body1" className={classes.link} style={{ color: linkColor }}>
                Overview
              </Link>
              <Link href="/aboutUs?id=1" variant="body1" className={classes.link} style={{ color: linkColor }}>
                Vision, Mission and Values
              </Link>
              <Link href="/aboutUs?id=2" variant="body1" className={classes.link} style={{ color: linkColor }}>
                Sustainability
              </Link>
              <Link href="/aboutUs?id=3" variant="body1" className={classes.link} style={{ color: linkColor }}>
                ISO Certificate
              </Link>
              <Link href="/aboutUs?id=4" variant="body1" className={classes.link} style={{ color: linkColor }}>
                Technological Innovation
              </Link>
              <Link href="/aboutUs?id=5" variant="body1" className={classes.link} style={{ color: linkColor }}>
                Management
              </Link>
            </Grid>
          </Toolbar>
        )
        : null}
      <MenuDrawer drawerState={drawerState} toggleDrawer={toggleDrawer} />
    </AppBar>
  );
};

export default TopAppBar;
