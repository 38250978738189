import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
    zIndex: 1000,
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '3px',
  },
  fabText: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontWeight: 600,
    fontSize: 16,
    paddingTop: 2,
    marginLeft: 10,
    textTransform: 'none',
  },
  fabTextSmall: {
    [theme.breakpoints.down('sm')]: {
      width: 0,
      marginLeft: 0,
    },
  },
}));

const LostLuggageFab = () => {
  const classes = useStyles();
  const [fabText, setFabText] = useState(false);

  useEffect(() => {
    const interval = setTimeout(() => {
      setFabText(true);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Fab variant="extended" color="primary" className={classes.fab} target="_blank" href="https://bags.wgh.no/airline">
      <img src="/static/images/CargoTracking.svg" width={25} height={25} alt="Lost luggage" />
      <Box className={clsx(classes.fabText, fabText && classes.fabTextSmall)}>
        Delayed luggage
      </Box>
    </Fab>
  );
};
export default LostLuggageFab;
