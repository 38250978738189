import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Typography, Link } from '@material-ui/core';
import { Box } from '@mui/material';
import moment from 'moment';
// Styles
const useStyles = makeStyles((theme) => ({
  backgroundImage: {
    backgroundImage: "url('/static/images/PhotoFooter.jpg')",
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    flexGrow: 1,
    position: 'relative',
    bottom: 0,
  },
  contactButtons: {
    width: '100%',
    padding: 30,
    marginTop: 20,
  },
  divider: {
    width: '100%',
    height: 1,
    backgroundColor: 'white',
    marginTop: 120,
    marginBottom: 80,
    [theme.breakpoints.down('sm')]: {
      marginTop: 80,
      marginBottom: 40,
    },
  },
  contactText: {
    textAlign: 'center',
    marginBottom: 60,
    color: theme.palette.primary.main,
  },
  links: {
    marginTop: '5px',
  },
  logo: {
    alignItems: 'center',
    justifyContent: 'flex-end',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  iataLogo: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  bottomLinks: {
    textAlign: 'left',
    marginTop: 60,
    [theme.breakpoints.down('sm')]: {
      marginTop: 40,
    },
  },
  contactHeaderText: {
    textAlign: 'left',
  },
}));

const Footer = () => {
  const classes = useStyles();
  return (
    <Box className={classes.backgroundImage} mt={{ xs: 6, md: 12 }}>
      <Box mt={6} p={{ xs: 2, sm: 4, md: 12 }} pb={{ xs: 1, sm: 2, md: 4 }}>
        <Typography variant="h1" className={classes.contactText}>CONTACT US</Typography>
        <Grid container spacing={8} justifyContent="center">
          <Grid item xs={12} sm={12} lg={4}>
            <Typography variant="subtitle2" color="secondary" className={classes.contactHeaderText}>
              Don&apos;t hesitate to contact us for any service request:
            </Typography>
            <Button variant="outlined" href="/contact" color="secondary" className={classes.contactButtons}>
              <Typography variant="h6">Contact information</Typography>
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} lg={4}>
            <Typography variant="subtitle2" color="secondary" className={classes.contactHeaderText}>
              Need to reach a specific airport? Visit our locations:
            </Typography>
            <Button variant="outlined" href="/locations" color="secondary" className={classes.contactButtons}>
              <Typography variant="h6">Locations</Typography>
            </Button>
          </Grid>
        </Grid>
        <Box className={classes.divider} />
        <Grid container spacing={4} justifyContent="space-between" style={{ textAlign: 'left' }}>
          <Grid item xs={12} sm={12} md={12} lg={5} container>
            <Grid item xs={4} md={4} style={{ flexDirection: 'column', display: 'flex' }}>
              <Typography variant="h6" color="secondary">Navigate</Typography>
              <Link href="/" variant="subtitle2" color="secondary" className={classes.links}>Home</Link>
              <Link href="/aboutUs" variant="subtitle2" color="secondary" className={classes.links}>About Us</Link>
              <Link href="/locations" variant="subtitle2" color="secondary" className={classes.links}>Locations</Link>
              <Link href="/news" variant="subtitle2" color="secondary" className={classes.links}>News</Link>
              <Link href="/career" variant="subtitle2" color="secondary" className={classes.links}>Career</Link>
            </Grid>
            <Grid item xs={5} md={4} style={{ flexDirection: 'column', display: 'flex' }}>
              <Typography variant="h6" color="secondary">Services</Typography>
              <Link href="/services/ground-handling" variant="subtitle2" color="secondary" className={classes.links}>Ground handling</Link>
              <Link href="/services/cargo-handling" variant="subtitle2" color="secondary" className={classes.links}>Cargo handling</Link>
              <Link href="/services/anti-icing" variant="subtitle2" color="secondary" className={classes.links}>De- &amp; anti-icing</Link>
              <Link href="/services/security-services" variant="subtitle2" color="secondary" className={classes.links}>Security services</Link>
              <Link href="/services/executive-services" variant="subtitle2" color="secondary" className={classes.links}>Executive services</Link>
            </Grid>
            <Grid item xs={3} md={4} style={{ flexDirection: 'column', display: 'flex' }}>
              <Typography variant="h6" color="secondary">Social</Typography>
              <Link href="https://www.linkedin.com/company/widerøe-ground-handling-as" variant="subtitle2" color="secondary" className={classes.links}>LinkedIn</Link>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={3} xl={2} className={classes.iataLogo}>
            <img src="/static/images/IATALogo.png" height={150} width={150} alt="IATA logo footer" />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={3} xl={2} className={classes.iataLogo}>
            <Box style={{
              backgroundColor: 'rgba(255, 255, 255, 1.0)', paddingTop: 10, paddingLeft: 10, paddingRight: 10, borderRadius: 8,
            }}
            >
              <img src="/static/images/isocert.png" height={100} width={250} alt="IATA logo footer" />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={3} className={classes.logo}>
            <img src="/static/images/WGHWhite.svg" height={150} width={300} alt="WGH logo footer" />
          </Grid>
        </Grid>
        <Grid container spacing={4} justifyContent="space-between" className={classes.bottomLinks}>
          <Grid item xs={12} lg={7} container spacing={2}>
            <Grid item style={{ marginRight: 40 }}>
              <Link href="/terms" variant="body1" color="secondary">Terms and conditions</Link>
            </Grid>
            <Grid item style={{ marginRight: 40 }}>
              <Link href="/privacy" variant="body1" color="secondary">Privacy policy</Link>
            </Grid>
            <Grid item>
              <Link href="https://www.objective.se" variant="body1" color="secondary">Website by Objective</Link>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={5} style={{ alignItems: 'center', justifyContent: 'flex-end', display: 'flex' }}>
            <Link href="/" variant="body1" color="secondary">
              {moment().format('YYYY')}
              {' '}
              ©  Widerøe Ground Handling AS
            </Link>
          </Grid>
        </Grid>
      </Box>

    </Box>
  );
};

export default Footer;
